/* ============================================== * ( Global Styles ) * ============================================== */

html, body {
  min-width: 320px; 
  background: #fff;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

::-webkit-scrollbar { 
    display: none; 
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

h1, h2, h3, h4, h5, li, button, blockquote, button p {
  font-family: 'Helvetica Neue', 'Raleway', sans-serif;
  font-weight: 100;
  letter-spacing: 1.5px;
  color: #35383A;
}

h1 {
  font-size: 3vw;
  line-height: .9em;
}

h1.heading,
h2.heading {
  font-size: 3.4vw;
  padding: 1.5% 0;
  text-indent: 7%;
  word-spacing: 3px;
  letter-spacing: 2px;
  color: #35383A;
  // background: #dad9d7;
  background: #F1EFEC;
  position: relative;
  top: -4.5px;
  margin-bottom: -4.5px;
}

h1.right {
  text-align: right;
  font-weight:300;
  padding-right: 7%;
}

h2.heading-no-bg {
  word-spacing: 3px;
  letter-spacing: 2px;
  font-size: 4vw;
  color: rgb(99,88,77);
}

h3.box {
  text-transform: uppercase;
  font-size: 4vw;
  letter-spacing: 7px;
}

span.raleway-500 {
  font-weight: 500;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  outline: none;
  cursor: hand;
}

p, td {
  color: #333;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1.7em;
  margin: 0 auto;
}

.spacing {
    p {
      margin-bottom: 1.4em;
      padding: 0

      &:last-child {
        margin-bottom: 0; 
      }
    }
 }

.white-smoke {
  background: #eee;
}

.egg-blue {
  background: rgba(183,199,226,0.5);
}

.water-leaf {
  // background: rgba(183,199,226,0.75);
  background: #fd6b54;
}

.storm-grey {
  background: rgba(156, 177, 213, 0.8);
}

.humming-bird {
  background: #E0F6EF;
}

.fair-pink {
  background: #0BB3DE
}

.white-lilac {
  background: #50E587;
}

.col-35 {
  width: 35%;
  height: 70vh;
  display: inline-block;
  position: relative;
}

.col-50 {
  float: left;
  width: 50%; 
  position: relative;
  padding: 10% 5%;
}

.col-65 {
  width: 64.5%;
  height: 70vh;
  display: inline-block;
}

.float-r {
  float: right;
}

span.small-h1 {
  display: block;
  line-height: 1.3em;
  font-size: 3.2vw;
}

/* ============================================== * ( Header, Global Navigation and Footer ) * ============================================== */

header {
  position: relative;
  background: #ccd9ea;
}

#logo {
  width: 30%;
  float: left;
}

#logo img {
  width: 45%; 
  margin: 4.5% 0 4.5% 15%;
}

.btn-mobile-menu {
  display: none;
}

nav {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 1%;
  width: 65%;
  float: right;

    li {
      font-family: 'Raleway', sans-serif;
      font-weight: 300;
      font-size: 1.6vw;
      letter-spacing: 1.5px;
      display: inline-block;
      padding-left: 5%;
      vertical-align: bottom;

        a {
          display: block;
          position: relative;
          padding-bottom: 10px;
          border-bottom: 2px solid transparent;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;

          &:link,
          &:visited,
          &:hover,
          &:active {
            color: #54575a;
          }
        }
    } 
}

.back-to-top {
  position: fixed;
  width: 64px;
  height: 64px;
  right: 20px;
  bottom: 30px;
  background-color: rgb(80, 98, 107);
  border: 1px solid #4F5456;
  cursor: pointer;
  opacity: 0;
  -webkit-transform: scale(0.5) translate3d(0, 50px, 0);
  transform: scale(0.5) translate3d(0, 50px, 0);
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition: border-color 300ms ease-out, opacity 200ms ease-out, -webkit-transform 200ms ease-out;
  transition: border-color 300ms ease-out, opacity 200ms ease-out, transform 200ms ease-out;
  z-index: 8888;

    &:hover:before {
      -webkit-transform: translate3d(0, -2px, 0) rotate(-45deg);
      -moz-transform: translate3d(0, -2px, 0) rotate(-45deg);
      -ms-transform: translate3d(0, -2px, 0) rotate(-45deg);
      -o-transform: translate3d(0, -2px, 0) rotate(-45deg);
      transform: translate3d(0, -2px, 0) rotate(-45deg);
    }

    &:hover:after {
      -webkit-transform: translate3d(0, -2px, 0) rotate(45deg);
      -moz-transform: translate3d(0, -2px, 0) rotate(45deg);
      -ms-transform: translate3d(0, -2px, 0) rotate(45deg);
      -o-transform: translate3d(0, -2px, 0) rotate(45deg);
      transform: translate3d(0, -2px, 0) rotate(45deg);
    } 

    &:before, &:after {
      display: block;
      content: "";
      position: absolute;
      top: 30px;
      width: 24px;
      border-top: 2px solid #fff;
      -webkit-transition: -webkit-transform 175ms ease-out;
      -moz-transition: -webkit-transform 175ms ease-out;
      -ms-transition: -webkit-transform 175ms ease-out;
      -o-transition: -webkit-transform 175ms ease-out;
      transition: transform 175ms ease-out;
    }

    &:before {
      left: 12px;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &:after {
      left: 28px;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &.ks-appear {
      opacity: 1;
      -webkit-transform: scale(1) translate3d(0, 0, 0);
      -moz-transform: scale(1) translate3d(0, 0, 0);
      -ms-transform: scale(1) translate3d(0, 0, 0);
      -o-transform: scale(1) translate3d(0, 0, 0);
      transform: scale(1) translate3d(0, 0, 0);
    }
}

footer {
  background: #54575a;
}

footer ul {
  width: 70%;
  float: left;
}

footer ul li {
  float: left;
  margin-left: 2.5%;
  padding: 2% 0;
  font-size: .85em;
}

footer ul li a {
  display: block;
  position: relative;
  padding-bottom: 10px;
}

footer ul li a:link,
footer ul li a:visited,
footer ul li a:hover,
footer ul li a:active {
  color: #fff;
}

footer ul li a:before {
  content: " ";
  position: absolute;
  left: 0%;
  bottom: 3px;
  height: 1px;
  width: 0%;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

footer ul li a:after {
  content: " ";
  position: absolute;
  right: 0%;
  bottom: 3px;
  height: 1px;
  width: 0%;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

ul li a:hover:before,
ul li a:focus:before {
  width: 50%;
}

ul li a:hover:after,
ul li a:focus:after {
  width: 50%;
}

footer p {
  width: 30%;
  color: #fff;
  font-family: 'Helvetica Neue', 'Raleway', sans-serif;
  font-weight: 100;
  padding: 1.2% 0;
  margin-right: 2.5%;
  font-size: .8em;
  letter-spacing: 2px;
  text-align: right;
}



/* ============================================== * ( Homepage ) * ============================================== */

#feature-image {
  background: #ccd9ea;
  position: relative;
  height: 70vh;
  overflow: hidden;
}

#feature-image .feature-bg {
  position: absolute;
  height: 70vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../images/mom-helping-child-read.jpg);
  background-position: 0% 18%;
  background-size: cover;
}

#feature-text {
  position: absolute;
  z-index: 2;
  padding-left: 60%
}

#feature-text h2 {
  font-size: 3.4vw;
  line-height: 1.3em;
  text-align: right;
}

#feature-text h3 {
  margin-top: 10%;
  font-size: 2.5vw;
  line-height: 1.2;
  text-align: right;
}

.intro-boxes {
  display: table;
  width: 33.3333333333%;
  position: relative;
  float: left;
  padding: 0 5%;
  height: 80vh;
}

.center-box {
// background: #CBD8DE;
background: #fd6b54;
}

.flank-boxes {
/*  top: -10px;*/
  background: rgba(203,216,222,0.60);
}

.center-box h1 {
  display: table-cell;
  vertical-align: middle;
  font-size: 4.2vw;
  text-align: center;
  line-height: 1.2em;
}

.center-box span.small-h1 {
  margin-top: 10%;
}
 
.flank-boxes p,
.flank-boxes blockquote {
  font-family: 'Helvetica Neue', 'Raleway', sans-serif;
  font-weight: 100;
  display: table-cell;
  vertical-align: middle;
  color: #48494A;
  text-align: center;
  font-size: 1.7vw;
  text-align: right;
  letter-spacing: 1.5px;
}

.intro-boxes p {
  text-align: center;
}

.intro-boxes blockquote p {
  display: block;
  width: 100%;
  font-style: italic;
  float: none;
  text-align: center;
}


#kids {
  position: relative;
  bottom: 35px;
  margin-bottom: -40px;
}

#kids img {
  width: 100%;
  height: auto;
}

span.quote {
  font-size: 1.2vw;
  font-style: italic;
}

#values h2.heading {
  padding: 3% 0;
  text-indent: 0;
  text-align: center;
}

#values ol {
  position: relative;
}

#values ol li {
  float: left;
  width: 33.3333333333%;
  height: 0;
  padding: 3% 3% 30.3333333333% 3%;
  color: #333;
  font-size: 3.1vw;
}
 
          #values li span.numeric-style {
            font-size: 5vw;
          }



.parallax-two {
  background: url(../images/autisim-puzzle.jpg) repeat center; 
  background-attachment: fixed;
  height: 70vh;
  background-size: cover;
  position: relative; 
  overflow: hidden;
}

.parallax-two blockquote {
  margin-top: 20%;
}

        .parallax-two img {
          position: relative;
          width: 3.2vw;
        }

        .parallax-two img:first-child {
          bottom: 12px;
          margin-right: 1.5%;
        }

        .parallax-two img:last-child  {
          margin-left: 1.5%;
        }

        .parallax-two blockquote > p {
          width: 70%;
          text-align: center;
          font-weight: 300;
          font-size: 2.4vw;
          color:#000; 
        }

.parallax-two button {
  width: 20%;
  height: 11vh;
  margin: 6% 0 20% 40%;
  display: inline-block;
  border:none;
  outline: none;
  letter-spacing: 5px;
  text-transform: uppercase;
  background: rgb(183, 199, 226);
  font-size: 2.25vw;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.parallax-two button a:link,
.parallax-two button a:visited {
  color: #000;
}

.parallax-two button:hover {
  background: #7795A5;
}

/* ============================================== * ( About ) * ============================================== */

.eshanie {
  background: url(../images/eshanie-profile-photo.jpg) no-repeat left;
  background-size: cover;
}

span.about-h1 {
  font-size: 2.2vw;
  font-weight: 100;
}

.about-intro blockquote p {
  font-size: 1.4vw;
  margin: 12% 7% 0 0;
  text-align: right;
  float: right;
  width: 70%;
}

.about-intro img,        
.service-intro img {
  display: inline-block;
  width: 8%;
  margin: 0 2%;
}

#bio:nth-child(2) {
  float: left;
}



/* ============================================== * ( Philosophy ) * ============================================== */

.philosophy {
  background: url(../images/philosophy-1.jpg) no-repeat center;
  background-size: cover;
}

.box-height {
  display: table;
  height: 60vh;
}

.box-height > div {
  display: table-cell;
  vertical-align: middle;
}

#philosophy h3.box {
  font-size: 2.5vw;
  text-align: center;
  width: 85%;
}

/* ============================================== * ( Services ) * ============================================== */

.services-bg {
  background: url(../images/kids-learning-aba-approach.jpg) no-repeat center;     /*Load a better sized image */
  background-size: cover;
}

/*this class is shared with #team */
.service-intro p {
  font-family: 'Helvetica Neue', 'Raleway', sans-serif;
  letter-spacing: 1.5px;
  font-weight: 100;
  font-size: 1.4vw;
  margin: 12% 7% 0 0;
  text-align: right;
  float: right;
  width: 70%;
}
        /*this class is shared with #team */
        .service-intro p:last-child {
          margin-top: 3.5%;
        }

.treatment-bg {
  background: url(../images/eshanie-working-with-child.jpg) no-repeat center;
  background-size: cover;
}

#services img {
  width: 33.3333333333%;
  margin: 0;
  float: left;
  padding:0;
}

#services table {
  width: 80%;
  margin: 5% auto;
}

#services table td {
  height: 15vh;
  line-height: 1.4em;
  vertical-align: middle;
  border-bottom: 7.5px solid #fff;
}

.td-30 {
  width: 20%;
  padding: 0 1% 0 2%;
  text-transform: uppercase;
  border-right: 6.5px solid #fff;
}

.td-70 {
  width: 80%;
  padding: 1.5% 3%;
}

span.td-number {
  margin-right: 5%;
  display: block;
  float: left;
}

/* ============================================== * ( Team ) * ============================================== */

.team-bg {
  background: url(../images/aba-bi-working-with-child.jpg) no-repeat center;
  background-size: cover;
}

#team h2 {
  text-align: center;
  text-indent: 0;
}

.team-structure {
  width: 90%;
  margin: 0 auto;
  margin-top: 5%;
}

.team-structure p {
  text-align: center;
}

span.p-lrg  {
  width: 78%;
  margin: 0 auto;
  font-size: 1.7vw;
  word-spacing: 2.5px;
  letter-spacing: 2px;
  text-align: center;
  line-height: 1.2em;
  display: block;
  margin-bottom: 5%;
}

.box-content { 
  width: 40%;
  padding: 5%;
/*  background: #eee;*/
  float: left;
  margin-left: 6.66666666667%;;
  margin-bottom: 7%;
}

.box-content h3 {
  font-size: 2vw;
  margin-bottom: 3%;
}

.box-content p {
  text-align: left;
}

#bios > p {
  width: 60%;
  margin: 0 auto;
  margin-top: 3%;
  text-align: center;
}

.cd-container {
  width: 90%;
  margin: 0 auto;
}

main {
  position: relative;
  z-index: 2;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -ms-transition-property: -moz-transform;
  -o-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -ms-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

main.slide-out {
  /* the main element slides to the left when the author bio is visible */
  -webkit-transform: translateX(-700px);
  -moz-transform: translateX(-700px);
  -ms-transform: translateX(-700px);
  -o-transform: translateX(-700px);
  transform: translateX(-700px);
}

main.slide-out .cd-overlay {
  /* the overlay layer gets visible when the author bio slides in */
  display: block;
  -webkit-animation: cd-fade-in 0.3s;
  -moz-animation: cd-fade-in 0.3s;
  -ms-animation: cd-fade-in 0.3s;
  -o-animation: cd-fade-in 0.3s;
  animation: cd-fade-in 0.3s;
}

#cd-team li {
  margin-bottom: 2em;
  width: 29%;
  margin: 3% 0% 3.25% 3%;
  float: left;
  -webkit-border-radius: 0.25em;
  -moz-border-radius: 0.25em;
  -ms-border-radius: 0.25em;
  -o-border-radius: 0.25em;
  box-shadow: 0 0 10px rgba(92, 75, 81, 0);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

#cd-team figure {
  position: relative;
}

#cd-team img {
  display: block;
  width: 100%;
  border-radius: .25em .25em 0 0;
}

#cd-team .cd-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(132, 163, 179, 0.85);
  opacity: 0;
  border-radius: .25em .25em 0 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

#cd-team figure:hover {
  box-shadow: 0 0 10px rgba(132, 163, 179, 0.85);
}

#cd-team li:hover .cd-img-overlay {
  opacity: 1;
}

#cd-team .cd-img-overlay span, 
input, textarea, label {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 1.8vw;
  letter-spacing: 1.5px;
  color: #fff;
}

#cd-team .cd-member-info {
  padding: 7% 0;
  background: #8FADC1;
  text-align: center;
  position: relative;
  border-radius: 0 0 .25em .25em;
}

#cd-team .cd-member-info h3 {
  font-size: 1.3vw;
}

.cd-member-info:after {
    content: "";
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path fill="none" stroke="#000" stroke-width="3" stroke-miterlimit="10" d="M50 16v69M85 50H16"/></svg>');
    display: block;
    height: 45px;
    width: 45px;
    position: absolute;
    right: 10%;
    top: 50%;
    margin: 0;
    position: absolute;               
    top: 50%;                        
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
 } 

.cd-member-bio {
  position: fixed;
  top: 0;
  right: 0;
  width: 700px;
  height: 100%;
  overflow-y: auto;
  /* smooth scrolling on mobile phones and tablets */
  -webkit-overflow-scrolling: touch;
  background: #7D828E;
  z-index: 1;
  /* this how we move the author bio section off the canvas */
  -webkit-transform: translateX(700px);
  -moz-transform: translateX(700px);
  -ms-transform: translateX(700px);
  -o-transform: translateX(700px);
  transform: translateX(700px);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.cd-member-bio.slide-in {
  /* the author bio section slides in when the user clicks on the team member picture */
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.cd-member-bio .cd-bio-content {
  padding: 3% 8%;
  color: #FFF;
}

.cd-member-bio .cd-bio-content h1 {
    margin-top: 3%;
    text-align: center;
    font-size: 4vw;
    color: #fff;
    margin-bottom: 15%;
}

.cd-member-bio .cd-bio-content p {
  color: #fff;
}

.no-cssgradients .cd-member-bio .cd-bio-content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.cd-member-bio-close {
  width: 60px;
  height: 60px;
  overflow: hidden;
  position: fixed;
  top: 12px;
  right: 20px;
  background: url("../images/cd-icon-close.svg") no-repeat center center;
  white-space: nowrap;
  z-index: 3;
  text-transform: uppercase;
  color: #fff;
  display: none;
}

.cd-member-bio-close.is-visible {
  display: block;
  -webkit-animation: cd-move-in 0.8s;
  -moz-animation: cd-move-in 0.8s;
  -ms-animation: cd-move-in 0.8s;
  -o-animation: cd-move-in 0.8s;
  animation: cd-move-in 0.8s;
}

.cd-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(132, 163, 179, 0.85);
  cursor: pointer;
  display: none;
}

.overflow-hidden {
  overflow-y: hidden;
}



/* -------------------------------- 

xkeyframes 

-------------------------------- */
@-webkit-keyframes cd-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-moz-keyframes cd-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes cd-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes cd-move-in {
  0% {
    -webkit-transform: translateX(100px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-move-in {
  0% {
    -moz-transform: translateX(100px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-move-in {
  0% {
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}


/* ============================================== * ( Contact ) * ============================================== */

.contact-bg {
  background: url(../images/SFYRKERH4N.jpg) no-repeat center;
  background-size: cover;
}

#contact h1 {
  margin-top: 5%;
  text-align: center;
  color: rgb(99,88,77);
  font-size: 4vw;
}

#contact-form p {
  margin-top: 5%;
}

#contact-area { 
  width: 80%;
  margin: 0 auto;
}

#contact-area input, 
#contact-area textarea,
.gender {
  color: #000;
  padding: 2%;
  letter-spacing: 1px;
  background: #aec0c9;
  border: 0;
  margin-bottom: 4%;
  transition: all .3s ease;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

::-webkit-input-placeholder {
   color: #000;
   opacity: .7;
}

:-moz-placeholder { /* Firefox 18- */
   color: #fff;
   opacity: .7;  
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #fff; 
   opacity: .7;
}

:-ms-input-placeholder {  
   color: #fff;
   opacity: .7;  
}

:-o-input-placeholder {  
   color: #fff; 
   opacity: .7; 
}

#contact-area input:focus, 
#contact-area textarea:focus {
  background-color:#8EB6CA;
  color: #fff;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-bottom: 1px solid #fff;
  border-right: 1px solid transparent;
}

.two-inputs {
  width: 48%; 
 float: left;
}

input:first-child,
input:nth-child(4),
input:nth-child(6),
input:nth-child(7) {
  margin-right: 4%;
}

.full-input {
  width: 100%;
}

.age-field {
  width: 15%;
  float: left;
}

div.gender {
  display: inline-block;
  margin: 0 0 0.75em 0;
  width: 29%;
}

div.gender label {
  margin-right: 5%;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 1.8vw;
  color: #000;
}

input[type="radio"] + label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

input[type="radio"] + label span {
  background-color: #7D808C;
}

input[type="radio"]:checked + label span {
   background-color: #D3D5DA;

}

input[type="radio"] + label span,
input[type="radio"]:checked + label span {
  -webkit-transition: background-color 0.4s linear;
  -o-transition: background-color 0.4s linear;
  -moz-transition: background-color 0.4s linear;
  transition: background-color 0.4s linear;
}

#form-error p {
  color: #F17373;
  font-size: 1em;
  margin: 0;
}

#contactForm input.submit-button {
  width: 20%;
  float: right;
}

#contactForm input.submit-button:hover {
  background: #8EB6CA;
  color: #fff;
}



/* ============================================== * ( Thank You ) * ============================================== */

#thank-you  {
    background: #8abeb2;
}




/* ============================================== ***************** ============================================== */
/* ==============================================   Media Queries)  ============================================== */
/* ============================================== ***************** ============================================== */

@media screen and (max-width: 2145px) {

  /*Global*/ 

  .col-50 {
    padding: 5%;
  }

  /*Homepage*/

  .intro-boxes {
    height: 66vh;   
  }

  /*Philosophy*/

  h1.heading, h2.heading {
    font-size: 4vw;
    padding: 2% 0;
  }

  .box-height {
    height: 74vh;
}

  #philosophy h3.box {
    font-size: 3vw;
  }

  /*Services*/ 

  #services table td {
    height: 8vh;
  }

  /*Contact*/ 
  span.p-lrg  {
    font-size: 2.1vw;
  }

  #bios > p {
    width: 80%;
  }  
}

@media screen and (max-width: 1845px) {
  .intro-boxes {
    height: 63vh;   
  }

  .col-35, 
  .col-65 {
    height: 60vh
  }
}

@media screen and (max-width: 1370px) {
  .intro-boxes {
    height: 458px; 
  }
}

@media screen and (max-width: 1200px) {

  /*global styles */

  p, td {
  font-size: 1em;
}

  span.p-lrg {
    font-size: 1.85vw;
  }

  .col-35,
  .col-65 {
    height: 50vw;
  }

  /* Homepage */

  #feature-image,
  #feature-image .feature-bg {
    height: 538px;
  }

  .parallax-two {
    height: 55vh;
  }

  .parallax-two button {
    height: 8vw;
    width: 22%;
    margin-left: 39%;
  }

 /* Philosophy */

  .box-height {
    height: 480px;
  }

  #philosophy h3.box {  
    font-size: 2.6vw;
  }

  /* Team */
  
  main.slide-out {
    -webkit-transform: translateX(-600px);
    -moz-transform: translateX(-600px);
    -ms-transform: translateX(-600px);
    -o-transform: translateX(-600px);
    transform: translateX(-600px);
  }

  .cd-member-bio {
    width: 600px;
    -webkit-transform: translateX(600px);
    -moz-transform: translateX(600px);
    -ms-transform: translateX(600px);
    -o-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  /*Contact*/

  #contactForm input,
  .gender {
    height: 55px;
  }

  .gender {
    position: relative;
  }

  input[type="radio"] + label {
    margin: 0;
    position: absolute;               
    top: 50%;                         
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }

  #contactForm label:nth-child(4) {
    margin-left: 35%;
  }
}

@media screen and (max-width: 1024px) {
  
  /*navigation */

  nav {
    width: 70%;
  }

/*  Homepage*/

  #feature-text h2 {
    font-size: 4.1vw;
  }

  #feature-text h3 {
    font-size: 3.2vw;
  }

  .flank-boxes {
    padding: 0 2.5%;
  }

  .flank-boxes p,
  .flank-boxes blockquote {
  font-size: 1.1em;
}

  /*About */

  .about-intro blockquote p {
    font-size: .9em;
    width: 85%;
  }

  nav li {
    font-size: 1em;
  }

  /*Team */

  .team-structure {
    width: 100%;
  }

  .box-content h3 {
    font-size: 2.6vw;
  }

  .box-content {
    width: 44%;
    margin-left: 4%;
  }

  main.slide-out {
    -webkit-transform: translateX(-470px);
    -moz-transform: translateX(-470px);
    -ms-transform: translateX(-470px);
    -o-transform: translateX(-470px);
    transform: translateX(-470px);
  }

  .cd-member-bio {
    width: 470px;
    -webkit-transform: translateX(470px);
    -moz-transform: translateX(470px);
    -ms-transform: translateX(470px);
    -o-transform: translateX(470px);
    transform: translateX(470px);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .cd-member-info:after {
    right: 5%;
    height: 35px;
    width: 35px;
  }
}

@media screen and (max-width: 915px) {

  /*navigation */

  #logo {
    width: 22%;
  }

  #logo img {
    width: 65%;
  }

  nav {
    width: 78%;
  }

  /*The Team */

  #cd-team .cd-member-info h3 {
    font-size: .9em;
  }

}

@media screen and (max-width: 820px) {

  /*mobile navigation kicks in */

  .btn-mobile-menu {
    margin: 3.5% 4% 0 0;
    float: right;
    cursor: pointer;
    display: block;
    width: 45px;
    height: 27px;
    background-color: transparent;
    background-image: url('data:image/svg+xml;utf8,<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="-615.5 -534.8 669 400.7"><style>.st0{fill:#fff;}</style><path class="st0" d="M-615.5-534.8h669v73.6h-669zM-615.5-207.7h669v73.6h-669zM-615.5-371.2h669v73.6h-669z"/></svg>');
    background-size: cover;
    border: none;
    outline: none;
    text-indent: 180%;
    white-space: nowrap;
    overflow: hidden;   
  }

  #nav {
    max-height: 0;
    position: relative;
    background: #c4d8e2;
    width: 100%;
    padding: 0; 
    float: none;
    overflow: hidden;
    float: none;
    transition: all 0.5s ease; 
    -webkit-transition: all 0.5s ease-out; 
    -moz-transition: all 0.5s ease-out; 
    -ms-transition: all 0.5s ease-out; 
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;  
  }

  #logo img {
    margin: 8.5% 0 6.5% 15%;
  }

  footer ul li, 
  footer p {
    padding: 3.5% 0;
  }

  button:hover {
    border: none;
  }

  #nav li {
    display: block;
    text-align: center;
    padding: 1.5% 0;
    border-bottom: 1.5px solid #fff;
  }

  #nav li:last-child {
    border-bottom: none;
  }

  #nav li a {
    padding: 0;
  }
  
  #nav li a:hover {
    border-bottom: 2px solid transparent;
  }

  .show-mobile-menu #nav {
    max-height: 281px;
  }


  /*global styles */

  .col-65 {
    display: block;
    float: left;
  }

  p, td {
    font-size: 0.875em;
  }

  /*Homepage */

  .parallax-two {
    height: 50vw;
  }

  /* Philosophy */

  .box-height {
    height: 385px;
  }

  /*Team */

  #cd-team li {
    width: 31%;
    margin: 3% 0% 3.25% 1.75%;
  }


  /*Contact*/

  #contact-area {
    width: 90%;
    margin-bottom: 5.5%;
  }

  span.p-lrg {
    width: 85%;
  }

  footer ul {
      width: 74%;
      margin: 0 auto;
      float: none;
  }

  footer ul li {
    width: 15%;
    margin-left: 2%;
    padding: 4% 0 4.5% 0;
    text-align: center;
  }

  footer ul li:first-child {
    margin-left: 0;
  }

  footer ul li a {
    padding: 0;
  }

  footer p {
    width: 100%;
    float: none;
    padding-top: 0;
    text-align: center;
  }
}


@media screen and (max-width: 710px) { 


  /*global styles */

  h1,
  .cd-member-bio .cd-bio-content h1 {
    font-size: 4.5vw;
  }

  span.about-h1{
    font-size: 2.9vw;
  }

  h1.heading, h2.heading {
    text-indent: 5%;
  }

  section#about-me:before,
  section#my-philosophy:before,
  section#services:before,
  section#team:before {
    letter-spacing: 2px;
    width: 100%;
    font-family: 'Helvetica Neue', 'Raleway', sans-serif;
    font-weight: 100;
    text-indent: 5%;
    display: block;
    font-size: 4vw;
    color: #fff;
    padding: 2% 0;
    background: #7795A5;
    text-transform: uppercase;
  }

  /*Footer */

  footer ul {
    width: 90%;
  }

  /*Homepage*/

  #feature-image, 
  #feature-image .feature-bg {
    height: 400px;
  }

  #feature-text h2,
  .center-box h1 {
    font-size: 4.5vw;
  }

  #feature-text h3,
  span.small-h1 {
    font-size: 3.6vw;
  }

  .center-box {
    padding: 4% 0 2% 0;
  }

  .center-box span.small-h1 {
    display: none;
  }

  .center-box h1 {
    width: 25%;
    margin-left: 17.5%;
    display: inline-block;
    position: relative;
  }

  .center-box h1:after {
    white-space: pre;
    content: " Behaviour\A Consultant";
    display: block;
    width: 50%;
    position: absolute;
    right: -80%;
    top: 0%;
    border-left: 1px solid #000;
    padding-left: 37%;
  } 

  .flank-boxes:first-child {
    display: none;
  }

  .center-box,
  .flank-boxes  {
    width: 100%;
    height: auto;
    top: 0;
    background: #fff;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }

  .flank-boxes {
    height: 100px;
  }

  .flank-boxes p {
    padding: 1% 5% 2.5% 5%;
    text-align: center;
    font-size: .875em;
    letter-spacing: 2px;
  }

   #kids {
    position: static;
    margin-bottom: 0;
  }

  #values h2 {
    background: none;
  }
  
  .parallax-two {
    height: 320px;
  }

  .parallax-two button {
    width: 25%;
    margin-left: 37.5%;
    font-size: 1em;
  }
  
  /*About */

  .col-35 {
    display: none;
  }
  .col-65 {
    width: 100%;
    float: none;
    height: 400px;
  }

  .float-r,
  .col-50 {
    float: none;
    width: 100%;
    padding: 2.5% 5%;
  }

  section#about-me:before {
    content: "About Me";
  }

  #bio h2,
  #my-philosophy h2,
  #treatment h2,
  #team > article:nth-child(3) > h2 {
    display: none;
  }
   
  #bio h3.center {
    font-size: 3.5vw;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    margin: 0;
    position: static;
  }

  /*Philosophy*/

  section#my-philosophy:before {
    content: "Core Principals";
  }

  .box-height {
    height: 80px;
  }

  #philosophy h3.box {
    font-size: 2.5vw;
    text-align: left;
    width: 100%;
    padding: 0 5%;
    text-transform: inherit;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 2px;
  }

  /*Services*/

  section#services:before {
    content: "ABA Treatment";
  }

  .treatment-bg {
    height: 350px;
  }

  #services table {
    width: 100%;
    margin: 0 auto;
  }

 /* The Team */

  section#team:before {
    content: "Team Roles";
  }

  .cd-container {
    width: 97.5%;
  }

  .cd-member-bio .cd-bio-content span.small-h1 {
    font-size: 3vw;
    margin-top: 10px;
  }

  .cd-bio-content p {
    font-size: .85em;
  }

  .cd-member-info:after {
    right: 6%;
    height: 25px;
    width: 25px;
  }

 /* Contact*/

  #contact-area {
    width: 95%;
  }

   #contactForm input,
  .gender {
    height: 45px;
  }
}

@media screen and (max-width: 571px) {

  /*Global*/

  h1.heading, 
  h2.heading {
    font-size: 1.9em
  }

  #logo img {
    width: 50%;
  }

  /*Homepage*/

  #feature-image, 
  #feature-image .feature-bg {
    height: 325px;
  }

  #feature-text h2, 
  .center-box h1 {
    font-size: 4.9vw;
  }

  #feature-text h3, 
  span.small-h1 {
    font-size: 4vw;
  }

  #values ol li {
    float: none;
    width: 100%;
    height: 80px;
    padding: 5%;
    color: #333;
    font-size: inherit; 
    text-align: center;
  }

  #values ol span.numeric-style {
    font-size: 1.5em;
  }

  #values ol li br {
    display: none;
  }

  .parallax-two blockquote > p {
    font-size: 1em;
    width: 85%;
  }

  .parallax-two button {
    height: 55px;
    width: 32%;
    margin-left: 34%;
  }

  footer ul {
    width: 100%;
  }

  /*Services*/

  #services table td {
    height: auto;
    border-bottom: none;
  }

  #services table td:first-child {
    padding-top: 5%;
  }

  #services table td:last-child {
    border-bottom: 7px solid #fff;
    padding-bottom: 5%;
  }
  
  .td-30, 
  .td-70 {
    display: block;
    width: 100%;
    padding: 0% 4%;
  }

  .td-30 {
    font-weight: bold;
    padding-bottom: 1.5%;
  }

  .td-30 {
    font-size: .95em;
  }

  span.td-number {
    margin-right: 1%;
  }

  /*Team*/

  span.p-lrg {
    font-size: 1em;
  }

  .box-content {
    width: 90%;
    margin-left: 5%;
  }

  .box-content h3 {
    font-size: 1.3em;
    text-align: center;
  }

  #cd-team li {
    width: 45%;
    margin: 3% 0% 3.25% 3.33333333333%;
  }

  main.slide-out {
    -webkit-transform: translateX(-390px);
    -moz-transform: translateX(-390px);
    -ms-transform: translateX(-390px);
    -o-transform: translateX(-390px);
    transform: translateX(-390px);
  }

  .cd-member-bio {
    width: 390px;
    -webkit-transform: translateX(390px);
    -moz-transform: translateX(390px);
    -ms-transform: translateX(390px);
    -o-transform: translateX(390px);
    transform: translateX(390px);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  .cd-member-info:after {
    right: 5%;
    height: 35px;
    width: 35px;
  }

  /*Contact*/

  .two-inputs {
    width: 100%;
    float: none;
  }

  .age-field {
    width: 47%;
  }

  div.gender {
    width: 49%;
    margin-bottom: .5%;
  }

  #cd-team .cd-img-overlay span, 
  input, textarea, label,
  input[type="radio"] + label {
    font-size: .8em;
  }

  #contact-area input, 
  #contact-area textarea, 
  .gender {
    margin-bottom: 1.5%;
  }
}

@media screen and (max-width: 466px) {

  /*Global*/
  h1.heading, 
  h2.heading {
    font-size: 1.9em
  }

  #logo img {
    width: 62px;
    margin: 7% 0 7% 15%;
  }

  footer ul li {
    display: none;
  }

  footer p {
    padding: 5% 0;
  }

  /*Homepage*/

  #feature-image, 
  #feature-image .feature-bg {
    height: 280px;
    background-position: 16% -14%;
  }

  #feature-text {
    padding-left: 56%;
  }

  #feature-text h2,
  #contact h1 {
    font-size: 5vw;
  }

  #feature-text h3,
  span.small-h1 {
    font-size: 4.1vw;
  }

  .center-box h1 {
    font-size: 6vw;
    margin-left: 14.5%;
  }

  .center-box h1:after {
    right: -90%;
    padding-left: 30%;
  } 

  #values ol li {
    height: 95px;
  }

  .parallax-two blockquote > p {
    width: 88%;
  }

  .parallax-two blockquote p br {
    display: none;
  }

  .parallax-two button {
    width: 35%;
    margin-left: 32.5%;
  }

  section#about-me:before,
  section#my-philosophy:before,
  section#services:before,
  section#team:before {
    font-size: 1.4em;
  }

   /*About*/

  .eshanie {
    background-position: 20% 0%;
  }

  .col-65 {
    height: 350px;
  }

  #bio h3.center,
  #philosophy h3.box {
    font-size: 1.2em;
  }

  /*Services*/

  .treatment-bg {
    height: 280px;
  }

  /*Team*/

  #cd-team li {
    width: 70%;
    margin-left: 15%;
  }

  main.slide-out {
    -webkit-transform: translateX(-340px);
    -moz-transform: translateX(-340px);
    -ms-transform: translateX(-340px);
    -o-transform: translateX(-340px);
    transform: translateX(-340px);
  }

  .cd-member-bio {
    width: 340px;
    -webkit-transform: translateX(340px);
    -moz-transform: translateX(340px);
    -ms-transform: translateX(340px);
    -o-transform: translateX(340px);
    transform: translateX(340px);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

 .cd-member-bio .cd-bio-content h1 {
    font-size: 5.5vw;
  }

  .cd-member-bio .cd-bio-content span.small-h1 {
    font-size: 4.2vw;
    margin-top: 5px;
  }

  .cd-member-bio-close {
    font-size: .85em;
  }
}

@media screen and (max-width: 375px) {
  
  /*Homepage*/

  #feature-image,
  #feature-image .feature-bg {
    background-position: 27% -14%;
  }

  #feature-text {
    padding-left: 54%;
  }

  #feature-text h2, 
  #contact h1 {
    font-size: 5.7vw;
  }

  #feature-text h3, 
  span.small-h1 {
    font-size: 4.8vw;
  }

  .parallax-two blockquote > p {
    width: 80%;
  }

  .parallax-two button {
    width: 50%;
    margin-left: 25%;
  }

  /*Services*/

  .treatment-bg {
    height: 241px;
    background-position: right;
  }

  /*Team */

  span.p-lrg {
    width: 90%;
    margin: 8% auto;
  }

  #bios > p {
    width: 90%;
  }

  #cd-team li {
    width: 90%;
    margin-left: 5%;
  }

  main.slide-out {
    -webkit-transform: translateX(-300px);
    -moz-transform: translateX(-300px);
    -ms-transform: translateX(-300px);
    -o-transform: translateX(-300px);
    transform: translateX(-300px);
  }

  .cd-member-bio {
    width: 300px;
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    -ms-transform: translateX(300px);
    -o-transform: translateX(300px);
    transform: translateX(300px);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }

  /*Contact*/

  .age-field {
    width: 40%;
  }

  div.gender {
    width: 56%;
  }

  #contactForm label:nth-child(4) {
    margin-left: 40%;
  }
}


